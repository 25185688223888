@import "/src/app/styles/index";

.content {
	border-radius: 13px;
	// width: 316px !important;
	// height: 53px !important;

	background: #f3faf7;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	color: #00242f;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
