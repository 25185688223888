@import "/src/app/styles/index";

.content {
	background: #002f3e;
	height: fit-content;
	min-height: 100vh;
	width: 100%;

	padding: 18px 39px;
}

.wrapperPanel {
	display: flex;
	max-width: 100%;
	height: fit-content;
	background: #002f3e;
}

.files {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: start;
	gap: 19px;
	//max-height: 90vh;
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-track {
		//background: rgba(255, 255, 255, 0.13);
		//border-radius: 38px;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(255, 255, 255, 0.13);
		border-radius: 38px;
	}
}

.item {
	border-radius: 13px;
	width: 252px;
	height: 194px;
	background: #0c393f;
	padding: 12px;

	color: #fff;
	word-break: break-all;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	font-family: $mainFontFamily;
	cursor: pointer;

	.itemImage {
		border-radius: 13px;
		max-width: 225px;
		max-height: 111px;
		object-fit: contain;
	}

	.itemTop {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.workareaTop {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	.unlock {
		display: flex;
		align-items: center;
		gap: 10px;

		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		text-align: right;
		color: #fff;
		opacity: 0.5;
	}
}

.allFiles {
	display: flex;
	align-items: center;
	gap: 6px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 24px;
	color: #fff;

	& > img {
		margin-top: 4px;
	}
}

.search {
	border-radius: 10px;
	width: 423px;
	height: 44px;
	background: rgba(124, 248, 196, 0.1);

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: rgba(255, 255, 255, 0.5);

	padding: 15px 20px;

	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
}

.top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 39px;
}
