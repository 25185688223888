@import "/src/app/styles/index";

.content {
	background: #0c393f;
	border-radius: 12px;

	width: 601px;
	height: fit-content;

	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	color: #fff;

	padding: 36px 40px 36px 40px;

	@include mediaMax(650px) {
		max-width: 94vw;
		padding: 14px;
	}

	.title {
		font-weight: 700;
		font-size: 32px;

		margin-bottom: 56px;

		@include mediaMax(650px) {
			margin-bottom: 24px;
		}
	}

	.mainContent {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: start;

		@include mediaMax(650px) {
			width: 100%;
		}
	}

	.text {
		margin-bottom: 23px;
	}

	.lockBlock {
		display: flex;
		gap: 10px;
		margin-bottom: 39px;

		@include mediaMax(650px) {
			margin-bottom: 24px;
		}
	}

	.lockBlockText {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: space-between;

		@include mediaMax(650px) {
			text-align: justify;
		}

		& > div:nth-child(2) {
			font-weight: 400;
			font-size: 14px;
			color: #fff;
			opacity: 0.75;
		}
	}

	.link {
		border-radius: 12px;
		width: 521px;
		height: 67px;
		background: rgba(124, 248, 196, 0.19);

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 13px;

		@include mediaMax(650px) {
			max-width: 100%;
		}

		& > img {
			cursor: pointer;
		}

		& > div {
			font-weight: 500;
			font-size: 16px;
			color: #fff;
			opacity: 0.75;

			@include mediaMax(650px) {
				width: 80%;
				word-wrap: break-word;
			}
		}
	}

	.btns {
		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-top: 47px;

		@include mediaMax(650px) {
			width: 100%;
		}

		.btnPassive {
			border: 1px solid #7cf8c4;
			border-radius: 10px;
			width: 140px;
			height: 44px;

			font-weight: 700;
			font-size: 16px;
			text-align: center;
			color: #fff;

			display: flex;
			align-items: center;
			justify-content: center;
            cursor: pointer;

            &:hover {
                background: #7cf8c4;
                color: #373737;
            }
		}

		.btnActive {
			background: #7cf8c4;
			border-radius: 10px;
			width: 140px;
			height: 44px;

			font-weight: 700;
			font-size: 16px;
			text-align: center;
            color: #373737;

			display: flex;
			align-items: center;
			justify-content: center;
            cursor: pointer;

            &:hover {
                background: #fff;
            }
		}
	}
}