@import "normalize.scss";
@import "media";
@import "main";

.wrapper {
	background: #002430;
	min-height: 100vh;
	position: relative;
	overflow-x: hidden;

	// &::after {
	// 	position: absolute;
	// 	content: "";
	// 	//background-image: url("../../assets/img/svg/shadow.svg");
	// 	top: 0;
	// 	left: 0;
	// 	width: 100vw;
	// 	height: 501px;
	// }
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}