@import "/src/app/styles/index";

.content {
	border-radius: 12px;
	width: 907px;
	height: 506px;
	background: #0c393f;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 46px 0 30px 0;

	@include mediaMax(1000px) {
		width: 90vw;
	}
}

.image {
    max-width: 100%;
    height: 220px;
	border-radius: 29px;
	margin-bottom: 20px;
}

.title {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 32px;
	line-height: 0%;
	text-align: center;
	color: #fff;
	margin-bottom: 54px;
}

.name {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #fff;
	margin-bottom: 14px;
}

.size {
	margin-bottom: 39px;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 14px;
	text-align: center;
	color: #fff;
	opacity: 0.75;
}

.btn {
	cursor: pointer;
	border-radius: 10px;
	width: 140px;
	height: 44px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
    display: flex;
    align-items: center;
    justify-content: center;

	&:hover {
		background: #f3faf7;
		color: #373737;
	}
}