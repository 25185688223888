@import "/src/app/styles/index";

.closeButton {
	position: absolute;
	top: 0;
	left: 0;
	width: 478px;
	height: 66px;

	@include mediaMax(500px) {
		width: 100%;
		max-width: 90vw;
	}
}

.wrapperMainPage {
	&::after {
		position: fixed;
		content: "";
		background-image: url("../../assets/img/svg/mainShadow.svg");
		background-repeat: no-repeat;
		background-size: cover;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 791px;
		z-index: 1;

		pointer-events: none;
	}
}

.content {
	font-family: $mainFontFamily;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	height: fit-content;

	@include mediaMax(500px) {
		padding: 0 25px;
	}
}

.title {
	font-weight: 800;
	font-size: 64px;
	text-align: center;
	color: #fff;
	max-width: 846px;
	margin-bottom: 40px;
	margin-top: 63px;

	@include mediaMax(600px) {
		font-size: 36px;
	}

	@include mediaMax(500px) {
		font-size: 32px;
		margin-top: 45px;
	}
}

.subTitle {
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #fff;
}

.icons {
	height: 446px;
	max-width: 1292px;
	width: 1292px;
	position: relative;
	margin-bottom: 73px;

	.text {
		position: absolute;
		top: 0;

		left: 50%;
		transform: translate(-50%, -50%);
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		color: #fff;

		@include mediaMax(500px) {
			top: -10px;
		}
	}

	.book {
		position: absolute;
		top: 0;
		left: 100px;
		filter: blur(6px);
		box-shadow: 0 0 20px 0 #c9d82a;
		width: 86px;
		height: 86px;
	}

	.button {
		position: absolute;

		top: 254px;
		left: 760px;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #ffcd01;
		width: 92px;
		height: 92px;
	}

	.caseImage {
		position: absolute;
		top: 100px;
		left: 774px;
		// top: 22.6%;
		// left: 59.9%;
		filter: blur(4px);
		box-shadow: 0 0 22px 0 #c2d933;
		width: 61px;
		height: 61px;

		@include mediaMax(500px) {
			display: none;
		}
	}

	.diagram {
		position: absolute;
		top: 131px;
		left: 460px;
		// top: 29.5%;
		// left: 35.6%;
		width: 110px;
		height: 110px;

		@include mediaMax(500px) {
			display: none;
		}
	}

	.key {
		position: absolute;
		top: 27px;
		left: 995px;
		// top: 6.2%;
		// left: 77%;
		width: 77px;
		height: 77px;
		box-shadow: 0 0 30px 0 rgba(255, 198, 1, 0.3);
		border-radius: 11px;

		@include mediaMax(500px) {
			left: 465px;
			top: 150px;
		}
	}

	.keyYellow {
		position: absolute;
		filter: blur(7px);
		box-shadow: 0 0 20px 0 #c4d82e;
		width: 60px;
		height: 60px;
		top: 169px;
		left: 0;
		// top: 37.8%;
		// left: 0;
		border-radius: 11px;

		@include mediaMax(500px) {
			left: 426px;
			top: 248px;
		}
	}

	.laptop {
		position: absolute;
		box-shadow: 0 0 30px 0 rgba(255, 198, 1, 0.3);
		width: 60px;
		height: 60px;
		top: 104px;
		left: 287px;
		// top: 23.3%;
		// left: 22.2%;
		border-radius: 11px;
	}

	.laptopOrange {
		position: absolute;
		width: 94px;
		height: 94px;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #ffa802;
		bottom: 365px;
		right: 13px;
		// bottom: 82%;
		// right: 1%;
		border-radius: 11px;

		@include mediaMax(500px) {
			right: 425px;
			top: 17px;
			width: 62px;
			height: 62px;
		}
	}

	.lightblub {
		position: absolute;
		width: 110px;
		height: 110px;
		box-shadow: 0 0 30px 0 rgba(255, 165, 1, 0.4);
		// top: 54.8%;
		// left: 9.7%;
		top: 244px;
		left: 125px;
		border-radius: 11px;
	}

	.lightblubBlue {
		position: absolute;
		bottom: 0;
		right: 65px;
		//right: 5%;
		width: 95px;
		height: 95px;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #69a1fe;
		border-radius: 11px;
	}

	.lock {
		position: absolute;
		width: 92px;
		height: 92px;
		box-shadow: 0 0 30px 0 rgba(202, 216, 42, 0.4);
		//bottom: 39.6%;
		right: 0;
		bottom: 177px;
		border-radius: 11px;

		@include mediaMax(500px) {
			right: 405px;
			top: 172px;
		}
	}

	.phone {
		position: absolute;
		width: 109px;
		height: 109px;
		box-shadow: 0 0 34px 0 #ff0174;
		top: 177px;
		right: 233px;
		// top: 43%;
		// right: 18%;
		border-radius: 11px;

		@include mediaMax(500px) {
			left: 388px;
			top: 11px;
		}
	}

	.settings {
		position: absolute;
		width: 86px;
		height: 86px;
		box-shadow: 0 0 30px 0 rgba(97, 153, 255, 0.4);
		border-radius: 11px;
		//bottom: 27.62%;
		//left: 52.9%;
		left: 683px;
		bottom: 124px;

		@include mediaMax(500px) {
			left: 627px;
			bottom: 256px;
		}
	}

	.diagramPurple {
		position: absolute;
		width: 69px;
		height: 69px;
		box-shadow: 0 0 34px 0 #ff0174;
		border-radius: 11px;
		// bottom: 33%;
		// left: 26%;
		bottom: 147px;
		left: 336px;

		@include mediaMax(500px) {
			display: none;
		}
	}
}

.buttonTry {
	border-radius: 10px;
	width: 164px;
	height: 44px;
	background: #7cf8c4;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	position: absolute;
	top: 52px;
	//left: 559px;
	//top: 8.7%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;

	&:hover {
		color: #373737;
		background: #f3faf7;
	}
}

.earlyAccessBlock {
	margin-top: -60px;

	@include mediaMax(500px) {
		margin-top: -175px;
	}
}

.subTitleProgram {
	font-family: $mainFontFamily;
	font-size: 40px;
	font-weight: 800;
	line-height: 50.28px;
	text-align: center;
	color: rgba(255, 255, 255, 1);
	margin-bottom: 53px;

	@include mediaMax(600px) {
		font-size: 32px;
		line-height: 30px;
		margin-bottom: 20px;
	}
}

.earlyAccess {
	width: 1010px;
	height: 213px;
	background-image: url("../../assets/img/svg/earlyAccessShadow.svg");
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 17px;
	border-radius: 13px;
	padding: 27px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	transition: 1s;

	&:hover {
		background-image: url("../../assets/img/svg/earlyAccessShadowHover.svg");
	}

	@include mediaMax(1072px) {
		max-width: 100%;
		width: 100%;
		word-break: break-word;
	}

	@include mediaMax(550px) {
		height: fit-content;
	}

	& > div:nth-child(1) {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 24px;
		text-align: center;
		color: #7cf8c4;
		margin-bottom: 36px;

		@include mediaMax(410px) {
			font-size: 20px;
			margin-bottom: 14px;
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 10px;

		& > div > span {
			font-weight: 800;
		}
	}

	.subText {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		z-index: 2;
	}
}

.descriptionBlock {
	max-width: 1010px;
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: #fff;
	opacity: 0.8;
	margin-bottom: 82px;

	@include mediaMax(1072px) {
		max-width: 100%;
		width: 100%;
	}

	@include mediaMax(500px) {
		margin-bottom: 37px;
	}

	& > div:nth-child(1) {
		margin-bottom: 30px;
	}

	& > div > span {
		font-weight: 700;
	}
}

.subTitleBenefits {
	margin-bottom: 44px;

	@include mediaMax(500px) {
		margin-bottom: 31px;
		max-width: 301px;
	}
}

.description {
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #fff;
	margin-bottom: 43px;

	@include mediaMax(500px) {
		max-width: 301px;
	}
}

.neoItems {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin-bottom: 70px;
	width: 100%;
	max-width: 1292px;

	transition: 1s;

	@include mediaMax(1250px) {
		justify-content: center;
	}

	@include mediaMax(500px) {
		margin-bottom: 31px;
		gap: 24px;
	}

	.neoItem {
		width: 385px;
		height: 296px;
		background-repeat: no-repeat;
		font-family: $mainFontFamily;
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		padding: 0 40px;
		border-radius: 29px;

		transition: 0.5s;

		@include mediaMax(600px) {
			width: 285px;
			height: 263px;
			font-size: 16px;
		}
	}

	.neoItemFirst {
		background-image: url("../../assets/img/svg/neoItemFirstShadow.svg");

		position: relative;
		top: 86px;
		z-index: 2;

		&:hover {
			background-image: url("../../assets/img/svg/neoItemFirstShadowHover.svg");
		}
	}

	.neoItemSecond {
		background-image: url("../../assets/img/svg/neoItemSecondShadow.svg");

		position: relative;
		top: 60px;
		z-index: 2;

		&:hover {
			background-image: url("../../assets/img/svg/neoItemSecondShadowHover.svg");
		}
	}

	.neoItemThird {
		background-image: url("../../assets/img/svg/neoItemThirdShadow.svg");

		position: relative;
		top: 0;
		z-index: 2;

		&:hover {
			background-image: url("../../assets/img/svg/neoItemThirdShadowHover.svg");
		}
	}
}

.animatePartner {
	transition: 2s;
	opacity: 1;
}

.animateNeoItemSecond {
	.neoItem {
		top: 0;
	}
}

.animateNeoItem {
	.neoItem {
		top: 0;
	}
}

.freeDemoButton {
	margin-bottom: 76px;
	border-radius: 10px;
	width: 164px;
	height: 44px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;

	&:hover {
		background: #f3faf7;
	}
}

.neoboxBlock {
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	max-width: 1292px;

	@include mediaMax(500px) {
		align-items: center;
	}

	.subTitleNeobox {
		margin-bottom: 66px;
		text-align: left;

		@include mediaMax(600px) {
			margin-bottom: 47px;
		}
	}
}

.neoboxDescription {
	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 32px;
	color: #fff;
	line-height: 60px;
	margin-bottom: 43px;

	@include mediaMax(1245px) {
		font-size: 20px;
		line-height: 154%;
	}

	@include mediaMax(460px) {
		padding-bottom: 30px;
	}

	@include mediaMax(412px) {
		padding-bottom: 80px;
	}

	& > div {
		height: 100px;
	}

	& > div > img {
		height: 50px;
		margin-right: 10px;
		margin-bottom: -10px;

		@include mediaMax(500px) {
			width: 31px;
			height: 31px;
		}
	}

	& > div > span {
		font-weight: 800;
	}
}

.neoboxSubDescription {
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: #fff;
	margin-bottom: 120px;

	@include mediaMax(700px) {
		margin-bottom: 20px;
	}

	@include mediaMax(500px) {
		margin-bottom: 56px;
		text-align: center;
	}
}

.textInEllipseBlock {
	max-width: 91px;
	text-align: center;

	position: absolute;
	top: 110px;
}

.textInEllipseBlock {
	max-width: 91px;
	text-align: center;

	position: absolute;
	top: 110px;
}

.ellipseBlock {
	cursor: pointer;

	z-index: 2;
	min-width: 180px;

	display: grid;
	justify-items: center;

	position: relative;
	left: 300px;
	top: 20px;

	transition: 1s;

	&:hover {
		circle {
			fill: white;
		}

		path {
			fill: #002430;
			stroke: #373737;
		}

		.textInEllipseBlock {
			color: #002430;
		}
	}

	@include mediaMax(700px) {
		top: 0;
	}
}

.animateEllipseBlock {
	transition: 1s;

	opacity: 1;
	.ellipseBlock {
		left: 0;
	}
}

.neoboxContent {
	display: flex;
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: #fff;

	@include mediaMax(700px) {
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 56px;
	}

	& > div:nth-child(1) {
		max-width: 1113px;
	}
}

.subTitleIntegrated {
	margin-bottom: 70px;
}

.partners {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 70px;
	width: 100%;
	max-width: 1292px;

	@include mediaMax(1250px) {
		justify-content: center;
	}

	@include mediaMax(500px) {
		margin-bottom: 65px;
	}

	.partnerBlock {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 14px;
	}

	.partner {
		width: 385px;
		height: 167px;
		background-image: url("../../assets/img/svg/deQuestShadow.svg");
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $mainFontFamily;
		font-weight: 600;
		font-size: 32px;
		line-height: 0;
		text-align: center;
		color: #fff;
		border-radius: 29px;
	}
}

.startBlocks {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	max-width: 1440px;
	width: 100%;
	font-family: $mainFontFamily;
	text-align: center;
	color: #fff;
	margin-bottom: 118px;

	@include mediaMax(1380px) {
		justify-content: center;
	}

	@include mediaMax(700px) {
		margin-bottom: 60px;
	}

	@include mediaMax(500px) {
		margin-bottom: 47px;
	}

	.startBlock {
		width: 645px;
		height: 236px;
		background-image: url("../../assets/img/svg/startFirstShadow.svg");
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		padding: 25px 0;
		position: relative;
		left: -300px;
		border-radius: 29px;
		z-index: 2;

		transition: 1s;

		&:hover {
			background-image: url("../../assets/img/svg/startFirstShadowHover.svg");
		}

		@include mediaMax(700px) {
			width: 100%;
			background-size: cover;
		}

		@include mediaMax(400px) {
			font-size: 16px;
			padding: 25px 34px 25px 24px;
		}
	}

	.startBlockTitle {
		font-weight: 800;
		font-size: 24px;
		line-height: 126%;
	}

	.startBlockText {
		font-weight: 600;
		font-size: 20px;
		max-width: 516px;

		@include mediaMax(532px) {
			font-size: 16px;
		}
	}

	.startBlockSecond {
		background-image: url("../../assets/img/svg/startSecondShadow.svg");

		position: relative;
		left: 300px;
		z-index: 2;

		&:hover {
			background-image: url("../../assets/img/svg/startSecondShadowHover.svg");
		}
	}

	.here {
		position: absolute;
		right: 114px;
		top: 30px;
		border-radius: 42px;
		width: 138px;
		height: 39px;
		background: #0c393f;
		font-family: $mainFontFamily;
		font-weight: 600;
		font-size: 16px;
		line-height: 0;
		text-align: center;
		color: #7cf8c4;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mediaMax(700px) {
			right: 9%;
			top: 27px;
		}

		@include mediaMax(532px) {
			left: 50%;
			top: 0px;
			transform: translate(-50%, -50%);
		}
	}

	.colorText {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		line-height: 137%;
		text-align: center;
		color: #00b3ff;
	}

	.colorTextSecond {
		color: #b846ee;
	}
}

.copyBlock {
	border-radius: 42px;
	background: #1e5259;
	padding: 10px;
	cursor: pointer;
	display: inline-block;

	@include mediaMax(484px) {
		padding: 14px 22px;
		margin: 10px 0;
	}

	& > img {
		margin-left: 5px;

		@include mediaMax(484px) {
			margin-top: 25px;
		}
	}
}

.animateStartBlock {
	transition: 1s;

	.startBlock {
		left: 0;
	}
}

.animateStartBlockSecond {
	transition: 1s;

	.startBlock {
		left: 0;
	}
}

.containerIcons {
	transition: 3s ease-in-out;
	img {
		transition: 3s ease-in-out;
	}
}

.subTitleTokenomics {
	@include mediaMax(600px) {
		margin-bottom: 65px;
	}
}

.whereBlocks {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	margin-bottom: 32px;
	max-width: 1440px;
	width: 100%;

	@include mediaMax(1358px) {
		justify-content: center;
	}

	.whereBlockClose {
		border-radius: 20px;
		width: 653px;
		min-height: 117px;
		height: fit-content;
		background: #012e3f;
		font-family: $mainFontFamily;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 27px 0 19px 0;

		& > div:nth-child(1) {
			font-weight: 700;
			font-size: 24px;
			line-height: 0%;
			text-align: center;
			color: #fff;
			margin-bottom: 27px;
		}

		.click {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			cursor: pointer;

			& > div:nth-child(2) {
				font-weight: 500;
				font-size: 14px;
				text-align: center;
				color: #fff;
				opacity: 0.8;
				margin-top: 6px;
			}

			.arrow {
				&.active {
					transform: rotate(180deg);
				}
			}
		}
	}
}

.clickBlocks {
	width: 100%;
	padding: 0 33px;
	margin-bottom: 16px;

	@include mediaMax(590px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 19px;
	}

	& > div:nth-child(1) {
		border-radius: 14px;
		max-width: 589px;
		width: 100%;
		height: 43px;
		background: #1f1f1f;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		@include mediaMax(590px) {
			width: 308px;
		}
	}

	& > img {
		cursor: pointer;
		background: #1f1f1f;
		border-radius: 14px;
		width: 100%;
		height: 72px;
	}

	.flamingo {
		height: 19px;
		width: 86px;

		@include mediaMax(590px) {
			width: 86px;
			height: 19px;
		}
	}

	.binance {
		width: 153px;
		height: 30px;

		@include mediaMax(590px) {
			width: 121px;
			height: 24px;
		}
	}

	.kucoin {
		width: 154px;
		height: 36px;

		@include mediaMax(590px) {
			width: 122px;
			height: 28px;
		}
	}

	.okx {
		width: 126px;
		height: 36px;

		@include mediaMax(590px) {
			width: 100px;
			height: 28px;
		}
	}

	.bitget {
		width: 151px;
		height: 40px;

		@include mediaMax(590px) {
			width: 119px;
			height: 32px;
		}
	}

	.gateio {
		width: 148px;
		height: 30px;

		@include mediaMax(590px) {
			width: 118px;
			height: 24px;
		}
	}

	.bingx {
		width: 136px;
		height: 40px;

		@include mediaMax(590px) {
			width: 109px;
			height: 32px;
		}
	}

	.clickBlocksContent {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin-top: 12px;

		& > div {
			border-radius: 14px;
			width: 100%;
			max-width: 188px;
			height: 72px;
			background: #1f1f1f;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			@include mediaMax(590px) {
				width: 148px;
			}
		}

		& > div:nth-child(2) {
			background: #2f7064;
		}
	}
}
