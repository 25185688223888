@import "/src/app/styles/index";

.inputBlock {
	display: flex;
	flex-direction: column;
	position: relative;

	@include mediaMax(555px) {
		width: 284px;
		justify-content: center;
	}
}

label {
	margin-bottom: 12px;
	text-transform: capitalize;

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 10px;
	color: #fff;
}

.input {
	width: 287px;
	height: 44px;
	background: transparent;
	outline: none;
	border: none;

	padding: 0 29px;
	font-family: $mainFontFamily;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;

	color: #ffffff;

	background-image: url("../../assets/img/svg/borderNeo.svg");
	background-size: cover;
	background-repeat: no-repeat;

	.input::placeholder {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		opacity: 0.5;
    }

    .input::-ms-input-placeholder {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		opacity: 0.5;
    }

    .input::-moz-placeholder {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		opacity: 0.5;
    }
}

.border {
	pointer-events: none;
	position: absolute;
	bottom: 0;
	margin-bottom: -3px;
}

.status {
	position: absolute;
	right: -43px;
	top: 40px;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.passwordControl {
	position: absolute;
	cursor: pointer;
	top: 38px;
	right: 14px;
	width: 22px;
	height: 20px;
	background: url("../../assets/img/svg/eye.svg") 0 0 no-repeat;
}
