@import "/src/app/styles/index";

.fileItem {
	border-radius: 13px;
	width: 252px;
	height: 194px;
	background: #0c393f;
	padding: 10px 12px;

	color: #fff;
	word-break: break-all;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 10px;
	font-family: $mainFontFamily;

	position: relative;

	&:hover {
		background: rgba(124, 248, 196, 0.15);
	}

	& > img {
		cursor: pointer;
	}

	.itemImage {
		border-radius: 13px;
		max-width: 225px;
		max-height: 111px;
		object-fit: contain;
	}

	.itemTop {
		display: flex;
		align-items: start;
		justify-content: space-between;

		& > img {
			cursor: pointer;
		}
	}
}

.deleteWindow {
	position: absolute;
	top: 36px;
	right: 12px;

	width: 189px;
	height: 132px;

	box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.25);
	background: #0c393f;
	border-radius: 5px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;

	.deleteWindowItem {
		display: flex;
		align-items: center;
		gap: 11px;

		width: 189px;
		height: 44px;

		cursor: pointer;

		padding-left: 10px;

		&:hover {
			background: rgba(124, 248, 196, 0.15);
		}
	}
}

.image {
	cursor: pointer;
	width: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
