@import "/src/app/styles/index";

.content {
	height: 138px;
	width: 100%;
	background: #002430;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding: 54px 39px;

	@include mediaMax(455px) {
		padding: 14px;
		height: fit-content;
	}

	.mainContent {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: start;
		gap: 80px;
		max-width: 1292px;
		margin: auto;

		@include mediaMax(860px) {
			gap: 10px;
			justify-content: space-between;
		}

		@include mediaMax(610px) {
			justify-content: center;
		}

		@include mediaMax(455px) {
			padding-bottom: 10px;
		}
	}

	.logo {
		font-family: $mainFontFamily;
		font-weight: 800;
		font-size: 16px;
		color: #fff;
		text-transform: uppercase;

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 13px;
		cursor: pointer;
		z-index: 2;

		@include mediaMax(390px) {
			font-size: 14px;
			gap: 5px;

			& > img {
				height: 30px;
			}
		}
	}

	.text {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		line-height: 0%;
		color: #fff;
		opacity: 0.5;

		@include mediaMax(390px) {
			font-size: 12px;
		}
	}
}
