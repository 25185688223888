@import "/src/app/styles/index";

.search {
	border-radius: 10px;
	width: 423px;
	height: 44px;
	background: rgba(124, 248, 196, 0.1);

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: rgba(255, 255, 255, 0.5);

	padding: 15px 20px;

	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
}

.top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 39px;
}

.blocks {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 29px;

	margin-bottom: 49px;

	.block {
		border-radius: 13px;
		width: 180px;
		height: 101px;
		background: #0c393f;

		padding: 13px 0 30px 13px;

		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		color: #fff;

		cursor: pointer;

		position: relative;

		& > img {
			margin-bottom: 15px;
		}

		& > label {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 13px;
			width: 180px;
			height: 101px;
		}
	}
}

.button {
	border-radius: 10px;
	width: 164px;
	height: 44px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: #f3faf7;
	}
}
