@import "/src/app/styles/index";

.content {
	width: 366px;
	height: 101px;

	background: #f3faf7;
	border-radius: 13px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	color: #00242f;

	.top {
		height: 43px;

		background: #e5f2ed;
		border-top-right-radius: 13px;
		border-top-left-radius: 13px;

		display: flex;
		align-items: center;
		justify-content: start;
		gap: 14px;

		padding: 0 17px;

        position: relative;

        .cross {
            position: absolute;
            top: 16px;
            right: 14px;

            cursor: pointer;
        }
	}

	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 58px;

		padding: 0 17px;

		font-weight: 500;
		font-size: 14px;
		color: rgba(0, 35, 46, 0.75);

		&>div:nth-child(1) {
			max-width: 240px;
		}
	}
}

.loader {
	width: 28px;
	height: 28px;
	border: 5px solid rgba(0, 35, 46, 0.1);
	border-bottom-color: #7CF8C4;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
