@import "/src/app/styles/index";

.contentActive {
	height: fit-content !important;
}

.contentHover {
	outline: 2px solid #7cf8c4;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.35);
	display: flex;
	align-items: center;
	justify-content: center;

	& > img:nth-child(1) {
		filter: blur(7.5px);
	}
}

.content {
	border-radius: 18px;
	width: 320px;
	min-height: 187px;
	position: relative;
	cursor: pointer;
}

.none {
	display: none;
}

.image {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	border-radius: 18px;
	width: 320px;
	height: 187px;
}

.mainContentActive {
	overflow-y: visible;
	height: fit-content !important;
	top: 93px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3 !important;
}

.mainContent {
	position: absolute;
	z-index: 2;
	left: 0;
	bottom: 0;

	padding: 9px 16px;
	width: 320px;
	backdrop-filter: blur(7.5px);
	//max-height: 77px;
	height: 94px;
	overflow-y: hidden;
	background: rgba(0, 0, 0, 0.35);
	border-bottom-left-radius: 18px;
	border-bottom-right-radius: 18px;

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		font-family: $mainFontFamily;
		font-weight: 800;
		font-size: 20px;

		color: #fff;

		margin-bottom: 16px;

		width: 100%;
	}

	.arrow {
		cursor: pointer;
		position: relative;
		z-index: 11;

		&.active {
			transform: rotate(180deg);
		}
	}

	.description {
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.75);
		// max-height: 27px;
		// overflow-y: hidden;
	}
}

.buttonHover {
	position: absolute;
	z-index: 3;
}

.button {
	border-radius: 10px;
	width: 117px;
	height: 44px;
	box-shadow: 0 0 54px 0 rgba(124, 248, 196, 0.67);
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 23px 0 16px 0;
	position: relative;
	z-index: 11;

	&:hover {
		background: #f3faf7;
	}
}
