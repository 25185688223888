@import "/src/app/styles/index";

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 284px;

    margin-bottom: 30px;
}