@import "/src/app/styles/index";

.content {
	width: 298px;
	min-height: 100vh;
	height: fit-content;
	min-width: 298px;

	background: #102d36;

	@include mediaMax(810px) {
		display: none;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 7px;

		margin-bottom: 52px;
		padding-top: 27px;
		padding-left: 39px;

		cursor: pointer;
	}

	.rows {
		display: flex;
		flex-direction: column;
		gap: 29px;

		padding: 0 39px;
		padding-bottom: 37px;

		.row {
			display: flex;
			align-items: center;
			gap: 8px;

			font-family: $mainFontFamily;
			font-weight: 500;
			font-size: 16px;
			color: #fff;

			cursor: pointer;

			position: relative;
		}
	}
}

.new {
	position: absolute;
	top: -10px;
	right: 111px;
	border-radius: 35px;
	width: 37px;
	height: 17px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 10px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;
}

.line {
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	height: 1px;
	margin-bottom: 39px;
}

.blue {
	border-radius: 100%;
	background: #39c3ff;
	width: 23px;
	height: 23px;
}

.purple {
	border-radius: 100%;
	background: #9c67f2;
	width: 23px;
	height: 23px;
}

.green {
	border-radius: 100%;
	background: #67f29f;
	width: 23px;
	height: 23px;
}

.rowsFolders {
	gap: 14px !important;
	padding-bottom: 0 !important;
}

.bold {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	color: #fff;
}

.button {
	border-radius: 10px;
	width: 218px;
	height: 44px;
	box-shadow: 0 0 54px 0 rgba(124, 248, 196, 0.35);
	background: #7cf8c4;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.block {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 205px;
}

.soon {
	border-radius: 57px;
	width: 79px;
	height: 23px;
	background: #16474e;

	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 8px;
	text-align: center;
	color: #7cf8c4;
	text-transform: uppercase;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	right: 0;
}

.uploadBlock {
	border-radius: 10px;
	width: 106px;
	height: 49px;
	box-shadow: 0 0 37px 0 rgba(124, 248, 196, 0.03);
	background: #16474e;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	color: #fff;

	padding: 14px 10px;
	margin-bottom: 7px;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;

	& > label {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 13px;
		width: 180px;
		height: 49px;
	}
}
