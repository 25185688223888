@import "/src/app/styles/index";

.block {
	width: 284px;
	height: 77px;
	padding: 0 26px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	border-radius: 10px;

	opacity: 1 !important;
	font-weight: 700;
	font-size: 13px;
	line-height: 140%;
}
.error {
	background: rgba(224, 35, 46, 0.1);
	border: 1px solid rgba(224, 35, 4, 0.2);
	color: #e0232e;
	a {
		text-decoration: none;
		color: #e0232e;
		border-bottom: 1px solid #e0232e;
	}
}
.done {
	margin-top: 30px;
	background: rgba(0, 216, 86, 0.15);
	border: 1px solid rgba(0, 216, 86, 0.2);
	padding: 12px 28px;
	color: #00d856;
}
.waiting {
	background: rgba(255, 170, 4, 0.1);
	border: 1px solid rgb(255, 170, 4, 0.2);
	color: #ffaa04;
}
