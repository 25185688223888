@import "/src/app/styles/index";

.content {
	background: #002f3e;
	height: fit-content;
	min-height: 100vh;
	width: 100%;

	padding: 18px 39px;

	.search {
		border-radius: 10px;
		width: 423px;
		height: 44px;
		background: rgba(124, 248, 196, 0.1);

		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		color: rgba(255, 255, 255, 0.5);

		padding: 15px 20px;

		display: flex;
		align-items: center;
		justify-content: start;
		gap: 8px;
	}

	.button {
		border-radius: 10px;
		width: 164px;
		height: 44px;
		background: #7cf8c4;

		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		color: #373737;

		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background: #f3faf7;
			color: #373737;
		}
	}
}

.wrapperPanel {
	display: flex;
	max-width: 100%;
	height: fit-content;
	background: #002f3e;
}

.top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 39px;
}

.blocks {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 29px;

	margin-bottom: 49px;

	.block {
		border-radius: 13px;
		width: 180px;
		height: 101px;
		background: #0c393f;

		padding: 13px 0 30px 13px;

		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		color: #fff;

		cursor: pointer;

		position: relative;

		& > img {
			margin-bottom: 15px;
		}

		& > label {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 13px;
			width: 180px;
			height: 101px;
		}
	}
}

.workarea {
	.workareaTop {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;

		.files {
			display: flex;
			align-items: center;
			gap: 6px;

			font-family: $mainFontFamily;
			font-weight: 700;
			font-size: 24px;
			color: #fff;

			& > img {
				margin-top: 4px;
			}
		}

		.unlock {
			display: flex;
			align-items: center;
			gap: 10px;

			font-family: $mainFontFamily;
			font-weight: 500;
			font-size: 16px;
			text-align: right;
			color: #fff;
			opacity: 0.5;
		}
	}

	.uploadArea {
		border: 1px dashed rgb(255, 255, 255, 0.5);
		border-radius: 27px;
		height: 278px;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.uploadArea {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		color: #fff;

		.import {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 24px;

			.importButton {
				color: #373737;
				border-radius: 10px;
				width: 164px;
				height: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #7cf8c4;
				cursor: pointer;

				font-family: $mainFontFamily;
				font-weight: 700;
				font-size: 16px;
				text-align: center;
				color: #373737;

				&:hover {
					background: #f3faf7;
					color: #373737;
				}
			}
		}
	}
}

.items {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: start;
	gap: 19px;
	margin-top: 20px;
	max-height: 412px;
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(255, 255, 255, 0.13);
		border-radius: 38px;
	}
}
