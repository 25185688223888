.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.Toastify__toast {
	border-radius: 7px !important;
	width: 478px !important;
	height: 66px !important;
	background: #f3faf7 !important;
	font-family: "Wix Madefor Display", sans-serif !important;
	font-weight: 700 !important;
	font-size: 20px !important;
	text-align: center !important;
	color: #373737 !important;
	z-index: 10 !important;
	padding: 0 !important;
}
@media (max-width: 500px) {
	.Toastify__toast {
		width: 100% !important;
		max-width: 90vw !important;
	}

	.Toastify__toast-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		width: 100vw !important;
		left: 50% !important;
	}

	.Toastify__toast-container--top-center {
		transform: translateX(-50%) !important;
		margin-top: 10px !important;
	}
}
.Toastify__toast > button > svg {
	display: none;
}

.Toastify__toast-body > div:nth-child(1) {
	display: none;
}

.Toastify__toast-body {
	padding: 0 !important;
}

.Toastify__toast-container {
	width: 478px !important;
}

#root {
	background: #002430;
	min-height: 100vh;
}

.view {
	background: url("../assets/img/svg/closeEye.svg") 0
		0 no-repeat !important;
	margin-top: -2px;
	right: 15px !important;
}