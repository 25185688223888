@import "/src/app/styles/index";

.wrapperHeader {
	background: #f3faf7;
	display: flex;
	justify-content: center;
	max-width: 100vw;

	@include mediaMax(810px) {
		background: #002f3e;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}

.content {
	max-width: 1440px;
	width: 100%;

	padding: 14px 30px 9px 30px;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	position: relative;
	z-index: 2;

	// @include mediaMax(500px) {
	// 	justify-content: center;
	// }
}

.menu {
	display: none;

	@include mediaMax(810px) {
		display: block;
		cursor: pointer;
	}
}

.logo {
	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 16px;
	text-align: center;
	color: #373737;
	text-transform: uppercase;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
	cursor: pointer;

	@include mediaMax(810px) {
		color: #ffffff;
	}
}

.features {
	text-decoration: none;
	color: #000000;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 44px;

	@include mediaMax(720px) {
		width: 100%;
		gap: 20px;
	}

	@include mediaMax(540px) {
		gap: 10px;
	}

	@include mediaMax(810px) {
		display: none;
	}

	.connect {
		border-radius: 10px;
		width: 164px;
		height: 44px;
		background: #7cf8c4;

		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		color: #373737;

		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			color: #fff;
			background: #002430;
		}
	}
}

.buttonsMobile {
	display: flex !important;
	flex-direction: column;
	justify-content: start;
	gap: 42px;
	width: 100vw;
	height: 100vh;
	background: #fff;
	padding-top: 26px;
	position: relative;
	z-index: 3;

	& > div:nth-child(1) {
		color: #00242f;
	}

	& > div:nth-child(2) {
		font-weight: 500;
	}

	& > div:nth-child(5) {
		margin-bottom: 12px;
	}


	.close {
		position: absolute;
		top: 37px;
		right: 26px;
		cursor: pointer;
	}
}

.select {
	position: relative;

	font-family: $subMainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	text-transform: uppercase;
	z-index: 10;
}

.selectBc {
	display: flex;
	align-items: center;
	gap: 14px;
	cursor: pointer;
	justify-content: center;

	border: 1.5px solid #002430;
	border-radius: 33px;
	width: 90px;
	height: 32px;
	padding: 9px;

	position: relative;
}

.selectBcValue {
	width: 90px;
	white-space: nowrap;
}

.selectBcArrow {
	transition: 0.5s;

	&.active {
		transform: rotate(180deg);
	}
}

.optionsSelectBc {
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 10;

	top: 57px;
	left: -2px;
	position: absolute;

	border: 1.5px solid #002430;
	border-radius: 33px;
	background: #f3faf7;
	width: 90px;

	padding: 5px 0;
}

.optionSelectBc {
	cursor: pointer;
	width: 90px;
	height: 49px;

	display: flex;
	align-items: center;
	gap: 14px;

	padding: 10px;
}

.app {
	cursor: pointer;
	position: relative;
	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 16px;
	text-align: center;
	color: #373737;

	& > div:nth-child(2) {
		position: absolute;
		top: -14px;
		right: -28px;
		border-radius: 35px;
		width: 37px;
		height: 17px;
		background: #7cf8c4;
		font-family: $mainFontFamily;
		font-weight: 800;
		font-size: 10px;
		text-align: center;
		color: #373737;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
