@import "/src/app/styles/index";

.content {
	border-radius: 12px;
	width: 678px;
	height: fit-content;
	background: #102d36;
	padding: 33px 20px 25px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include mediaMax(725px) {
		max-width: 98vw;
		width: 100%;
		height: fit-content;
	}

	.title {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 24px;
		text-align: center;
		color: #fff;
		word-break: break-word;
		margin-bottom: 40px;

		@include mediaMax(500px) {
			max-width: 100%;
			width: 100%;
			font-size: 20px;
		}
	}

	.image {
		margin-bottom: 13px;
		cursor: pointer;
	}

	.text {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		color: #fff;
		margin-bottom: 47px;
	}

	.textOpacity {
		display: block;
		font-family: $mainFontFamily;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		color: #fff;
		word-break: break-word;
		opacity: 0.8;
		margin-bottom: 30px;

		@include mediaMax(725px) {
			max-width: 100%;
			height: fit-content;
		}

		& > span {
			cursor: pointer;
			font-weight: 800;
		}
	}
}

.btn {
	border-radius: 10px;
	width: 89px;
	min-height: 44px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background: #f3faf7;
		color: #373737;
	}
}
