@import "/src/app/styles/index";

.wrapper {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include mediaMax(545px) {
		display: block;
	}

	&::after {
		position: fixed;
		content: "";
		background-image: url("../../assets/img/svg/shadowWelcome.svg");
		background-size: cover;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}
}

.content {
	font-family: $mainFontFamily;
	color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px;
	min-height: 640px;
	//height: 100vh;

	& > * {
		z-index: 3;
	}
}

.titleBlock {
	font-weight: 800;
	font-size: 64px;

	display: flex;
	align-items: center;
	gap: 34px;

	margin-bottom: 54px;
	margin-top: 20px;

	@include mediaMax(750px) {
		zoom: 0.7;

		margin-top: 46px;
	}

	@include mediaMax(545px) {
		zoom: 0.5;
	}

	@include mediaMax(400px) {
		zoom: 0.4;
	}

	.titleSvg {
		display: flex;
		align-items: center;
		gap: 17px;

		& > img:nth-child(1) {
			height: 76px;
		}

		& > img:nth-child(2) {
			height: 35px;
		}
	}
}

.subTitle {
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #fff;
}

.bold {
	font-weight: 700;
}

.icons {
	max-width: 1440px;
	width: 1440px;
	height: 451px;
	position: relative;
	margin-bottom: 65px;
	margin-top: -120px;

	@include mediaMax(1340px) {
		//width: 100%;
		//max-width: 100%;
	}

	@include mediaMax(545px) {
		display: none;
	}

	.button {
		position: absolute;
		bottom: 12.1%;
		left: 26.25%;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #ffcd01;
		width: 92px;
		height: 92px;
	}

	.caseImage {
		position: absolute;
		top: 0;
		left: 10%;
		filter: blur(4px);
		box-shadow: 0 0 22px 0 #c2d933;
		width: 61px;
		height: 61px;
	}

	.diagram {
		position: absolute;
		bottom: 89.8%;
		right: 6.8%;
		width: 69px;
		height: 69px;
		box-shadow: 0 0 34px 0 #ff0174;
		border-radius: 11px;
	}

	.key {
		position: absolute;
		bottom: 56.7%;
		left: 21.8%;
		width: 77px;
		height: 77px;
		box-shadow: 0 0 30px 0 rgba(255, 198, 1, 0.3);
		border-radius: 11px;
	}

	.laptopOrange {
		position: absolute;
		bottom: 15.2%;
		left: 65.9%;
		width: 94px;
		height: 94px;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #ffa802;
		border-radius: 11px;
	}

	.lightblubBlue {
		position: absolute;
		bottom: 5%;
		right: 2.6%;
		width: 95px;
		height: 95px;
		filter: blur(4px);
		box-shadow: 0 0 20px 0 #69a1fe;
		border-radius: 11px;
	}

	.lock {
		position: absolute;
		bottom: 47.6%;
		right: 11.6%;
		width: 92px;
		height: 92px;
		box-shadow: 0 0 30px 0 rgba(202, 216, 42, 0.4);
		border-radius: 11px;
	}

	.phone {
		position: absolute;
		bottom: 47.6%;
		left: 0;
		width: 109px;
		height: 109px;
		box-shadow: 0 0 34px 0 #ff0174;
		border-radius: 11px;
	}

	.settings {
		position: absolute;
		width: 86px;
		height: 86px;
		box-shadow: 0 0 30px 0 rgba(97, 153, 255, 0.4);
		border-radius: 11px;
		bottom: 12.1%;
		left: 46.6%;
	}
}

.buttonNeo {
	border: 1px solid rgba(255, 255, 255, 0.75);
	border-radius: 12px;

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
	cursor: pointer;
	z-index: 2;

	width: 284px;
	height: 44px;

	margin: 30px 0;

	&:hover {
		background: #f3faf7;
		color: #373737;
	}
}

.wOr {
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	align-items: center;

	text-align: center;
	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 400;
	line-height: 22.4px;

	width: 284px;

	svg {
		width: 94px;

		@include mediaMax(624px) {
			//width: 29.48vw;
		}
	}
}

.btn {
	border-radius: 10px;
	width: 284px;
	height: 44px;

	outline: none;
	border: none;

	background: #7cf8c4;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 24px;
}

.btnPassive {
	border: 1px solid rgba(124, 248, 196);
	border-radius: 12px;
	background: #002430;
	width: 284px;
	height: 44px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 9px;

	margin-top: 24px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #fff;

	outline: none;
}

.termsOfUse {
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 13px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.75);

	width: 284px;

	margin-top: 20px;

	& > span {
		font-weight: 800;
	}
}

.welcomeIcons {
	//max-width: 1042px;
	height: auto;

	//margin-top: -60px;
	z-index: 2;
}

.forgotPassword {
	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 13px;
	line-height: 140%;
	color: #fff;

	margin-top: 20px;

	cursor: pointer;
}

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.mainNetworksWrapper {
	margin: 20px 0;
}

.mainNetworks {
	display: flex;
	flex-wrap: wrap;
	align-items: start;
	justify-content: center;
	gap: 18px;

	margin-top: 20px;
}

.wallet {
	cursor: pointer;
	display: flex;
	align-items: center;

	height: 83px;
	width: 229px;

	background: rgba(255, 255, 255, 0.07);
	border-radius: 12px;

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #fff;

	padding-left: 15px;

	@include mediaMax(555px) {
		height: 63px;
		width: 174px;
		font-size: 15px;
	}

	img {
		width: 53px;
		height: 53px;

		object-fit: cover;
		border-radius: 50%;

		margin-right: 12px;

		@include mediaMax(555px) {
			height: 38px;
			width: auto;
			margin-right: 9px;
		}
	}

	&:hover {
		border: 2px solid #7cf8c4;
	}

	.imageBlock {
		display: flex;
		align-items: center;
	}
}
