@import "/src/app/styles/index";

.content {
	display: grid;
	justify-content: center;
	justify-items: center;
	align-content: start;

	color: #fff;

	text-align: center;
	font-family: $mainFontFamily;
	font-style: normal;
	font-weight: 600;
	line-height: 135%;

	margin-bottom: 102px;

	@include mediaMax(670px) {
		margin-bottom: 50px;
	}
}

.title {
	font-size: 40px;
	font-weight: 800;

	@include mediaMax(670px) {
		font-size: 32px;
	}
}

.wRoadmap {
	display: grid;
	justify-content: center;
	grid-auto-flow: column;

	position: relative;

	margin-top: 110px;

	@include mediaMax(1350px) {
		grid-auto-flow: row;
		align-content: start;
		row-gap: 10px;
	}
}

.arrow {
	display: none;

	@include mediaMax(1350px) {
		display: block;

		margin-bottom: 10px;
	}
}

.block {
	position: relative;

	display: grid;
	align-content: start;
	justify-items: center;

	width: 645px;
	height: 267px;

	border-radius: 26px;
	background-color: #002430;
	background-repeat: no-repeat;
	background-position: 0 0;

	padding-left: 50px;
	padding-right: 50px;

	@include mediaMax(670px) {
		width: 341px;
		height: 246px;
	}
}

.block1 {
	background-image: url("imgs/bgStage1.svg");

	@include mediaMax(670px) {
		width: 341px;
		height: 305px;

		background-image: url("imgs/mobileBgStage1.svg");
	}
}

.block2 {
	background-image: url("imgs/bgStage2.svg");

	margin-top: 234px;

	@include mediaMax(1350px) {
		margin-top: 0;
	}
}

.block3 {
	background-image: url("imgs/bgStage3.svg");
}

.block4 {
	background-image: url("imgs/bgStage4.svg");
}

.subTitleBlock {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;

	margin-top: 16px;

	@include mediaMax(670px) {
		font-size: 16px;

		margin-top: 12px;
	}
}

.subTitleBlock1 {
	color: #00b3ff;
}

.subTitleBlock2 {
	color: #b846ee;
}

.subTitleBlock3 {
	color: #f87ce4;
}

.subTitleBlock4 {
	color: #ff8b67;
}

.descriptionBlock {
	margin-top: 18px;

	font-size: 20px;
	font-weight: 600;
	line-height: 136.704%;

	@include mediaMax(670px) {
		margin-top: 12px;

		font-size: 16px;
	}
}

.additionalInfo {
	width: 138px;
	height: 39px;

	border-radius: 42px;
	background: #0c393f;

	color: #7cf8c4;

	font-size: 16px;
	font-weight: 600;

	position: absolute;
	right: 114px;
	top: 14px;

	display: grid;
	align-items: center;
	justify-items: center;

	@include mediaMax(1350px) {
		left: 50%;
		translate: -50%;
		top: -20px;
	}
}

.titleBlock {
	font-size: 24px;
	font-weight: 800;

	margin-top: 24px;

	@include mediaMax(1350px) {
		margin-top: 30px;
	}

	@include mediaMax(670px) {
		margin-top: 24px;

		font-size: 20px;
	}
}

.column {
	display: grid;
	align-content: start;
	row-gap: 200px;

	@include mediaMax(1350px) {
		row-gap: 26px;

		justify-items: center;
	}
}

.line {
	margin-top: 140px;

	@include mediaMax(1350px) {
		display: none;
	}
}
