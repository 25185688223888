@import "/src/app/styles/index";

.content {
	background: #00242F;
	padding: 52px 38px 81px 38px;
	font-family: $mainFontFamily;
	min-height: 720px;
	display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: center;

	.title {
		font-weight: 800;
		font-size: 48px;
		line-height: 0%;
		text-align: center;
		color: #fff;
		margin-bottom: 54px;

		@include mediaMax(520px) {
			font-size: 36px;
			margin-bottom: 40px;
		}

		@include mediaMax(415px) {
			font-size: 32px;
		}
	}

	.description {
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		color: rgba(255, 255, 255, 0.75);
		max-width: 596px;
		margin: auto;
		margin-bottom: 16px;

		@include mediaMax(520px) {
			margin-bottom: 20px;
		}

		& > span {
			color: #7cf8c4;
			font-weight: 800;
		}
	}
}

.item {
	border-radius: 18px;
	width: 320px;
	height: 187px;
	background: linear-gradient(327deg, #7a46ce 0%, #3c1c71 100%);

	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 20px;
	color: #fff;

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 33px;
	padding-top: 25px;

	& > img {
		width: 63px;
		height: 63px;
	}

	.mainContent {
		// position: absolute;
		// left: 0;
		// bottom: 0;
		backdrop-filter: blur(7.5px);
		height: 77px;
		width: 320px;
		overflow-y: hidden;
		background: rgba(0, 0, 0, 0.35);
		border-bottom-left-radius: 18px;
		border-bottom-right-radius: 18px;
		padding: 9px 16px;
	}
}

.wrapperItems {
	display: flex;
	flex-direction: column;
	justify-content: center;
    margin: auto;
}

.items {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 44px;
	margin-top: 26px;
	max-width: 1412px;
}

.menu {
	display: flex;
	align-items: center;
	gap: 49px;
}

.count {
	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	color: rgba(255, 255, 255, 0.5);
}

.select {
	position: relative;

	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	color: #fff;
	z-index: 10;
}

.selectBc {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	padding: 9px;

	border: 1px solid #7cf8c4;
	border-radius: 24px;
	width: 149px;
	height: 43px;

	position: relative;
}

.selectBcValue {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	//gap: 6px;
	white-space: nowrap;

	& > img {
		width: 24px;
	}
}

.selectBcArrow {
	transition: 0.5s;

	&.active {
		transform: rotate(180deg);
	}
}

.optionsSelectBc {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 14px;
	z-index: 10;

	top: 57px;
	left: -2px;
	position: absolute;

	background: #0c393f;
	border-radius: 8px;
	width: 187px;
	height: fit-content;

	padding: 10px;
}

.optionSelectBc {
	cursor: pointer;
	width: 100%;
	//height: 49px;

	display: flex;
	align-items: center;
	gap: 6px;

	//padding: 10px;

	& > img {
		width: 24px;
	}
}
