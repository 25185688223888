@import "/src/app/styles/index";

.content {
	background: #002f3e;
	height: fit-content;
	min-height: 100vh;
	width: 100%;

	padding: 18px 39px;
}

.wrapperPanel {
	display: flex;
	max-width: 100%;
	height: fit-content;
	background: #002f3e;
}

.search {
	border-radius: 10px;
	width: 423px;
	height: 44px;
	background: rgba(124, 248, 196, 0.1);

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 16px;
	color: rgba(255, 255, 255, 0.5);

	padding: 15px 20px;

	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
}

.top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 39px;
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-bottom: 39px;

	& > div:nth-child(2) {
		font-family: $mainFontFamily;
		font-weight: 700;
		font-size: 16px;
		text-align: right;
	}

	.publish {
		position: absolute;
		top: 40px;
		right: 0;
		border-radius: 8px;
		width: 272px;
		height: 49px;
		background: #0c393f;
		font-family: var(--font-family);
		font-weight: 500;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.75);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		z-index: 5;
	}
}

.files {
	display: flex;
	align-items: center;
	gap: 6px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 24px;
	color: #fff;

	& > img {
		margin-top: 4px;
	}
}

.item {
	border-radius: 18px;
	width: 320px;
	height: 187px;
	background: linear-gradient(327deg, #7a46ce 0%, #3c1c71 100%);

	font-family: $mainFontFamily;
	font-weight: 800;
	font-size: 20px;
	color: #fff;

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 33px;
	padding-top: 25px;

	& > img {
		width: 63px;
		height: 63px;
	}

	.mainContent {
		// position: absolute;
		// left: 0;
		// bottom: 0;
		backdrop-filter: blur(7.5px);
		height: 77px;
		width: 320px;
		overflow-y: hidden;
		background: rgba(0, 0, 0, 0.35);
		border-bottom-left-radius: 18px;
		border-bottom-right-radius: 18px;
		padding: 9px 16px;
	}
}

.items {
	display: flex;
	flex-wrap: wrap;
	gap: 44px;

	@include mediaMax(761px) {
		justify-content: center;
	}
}

.button {
	border-radius: 10px;
	width: 164px;
	height: 44px;
	background: #7cf8c4;
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	color: #373737;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: #f3faf7;
	}
}
